.Third-container {
  background-color: #fee4e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 650px;
  /* justify-content: center; */
  padding: 40px 30px 20px 30px;
}

.title-color {
  color: #780909;
}

.timeline {
  background-color: #780909;
  width: 100%;
  height: 1px;
  position: relative;
  margin-top: 15%;
}

.indicator-wrapper {
  width: 10%;
  position: absolute;
  height: 15px;
  display: flex;
}

.indicator {
  width: 1px;
  height: inherit;
  background-color: #780909;
}

.i1 {
  bottom: 0;
  left: 4%;
}
.i2 {
  top: 0;
  left: 20%;
}
.i3 {
  bottom: 0;
  left: 39%;
}
.i4 {
  top: 0;
  left: 59%;
}
.i5 {
  bottom: 0;
  left: 74%;
}
.i6 {
  top: 0;
  left: 96%;
}

.icon {
  position: absolute;
  left: -50%;
  width: 100%;
}

.i-place-top {
  bottom: 20px;
}

.i-place-bottom {
  top: 20px;
}

.text-time {
  font-size: 24px;
  color: #b04949;
  height: 36px;
  position: absolute;
  left: -50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: 700;
}

.t-place-top {
  bottom: 20px;
  align-items: flex-end;
}

.t-place-bottom {
  top: 20px;
  align-items: flex-start;
}

.photo-order-section {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.photo-order-icon {
  width: 10%;
}
.timeline-header {
  font-size: 54px;
}
@media only screen and (max-width: 576px) {
  .photo-order-section {
    margin-top: 100px;
  }

  .photo-order-icon {
    width: 15%;
  }
  .timeline {
    margin-top: 20%;
  }
}

.heart-line {
  width: 95%;
  margin: 20px 0;
}

.name-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  color: #b04949;
  font-size: 24px;
  text-align: center;
}

@media screen and (max-width: 370px) {
  .text-time {
    font-size: 12px;
    height: 24px;
  }

  .photo-order-section {
    margin-top: 80px;
  }
}
