@font-face {
  font-family: "hanyi"; /*Can be any text*/
  src: local("HanyiSentyPomelo"),
    url("./fonts/HanyiSentyPomelo.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  /* line-height: 1; */
}

.App {
  background-color: #6d0000;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.Main-container {
  position: relative;
  background-color: #fef5f4;
  display: flex;
  max-width: 650px;
  justify-content: center;
  flex-direction: column;
  padding: 0 !important;
}

.Secondary-container {
  background-color: #fef5f4;
  display: flex;
  max-width: 650px;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 80px;
}

.invitation {
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

.English-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #bb3434;
  padding-top: 70px;
  padding-bottom: 40px;
  text-align: center;
}

.text-h1 {
  font-size: 64px;
}

.text-h2 {
  font-size: 54px;
}

.text-getting-married {
  font-size: 48px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.text-h3 {
  font-size: 32px;
}

.text-h4 {
  font-size: 24px;
  max-width: 480px;
}

.text-date {
  font-size: 54px;
  margin: 20px 0;
}

@media only screen and (max-width: 768px) {
  .invitation {
    padding-left: 20px;
    padding-right: 20px;
  }
  .Secondary-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .invitation {
    padding-left: 10px;
    padding-right: 10px;
  }
  .English-section {
    padding-bottom: 20px !important;
    padding-top: 40px !important;
  }
  .Secondary-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 60px;
  }
  .text-h1 {
    font-size: 58px;
  }

  .text-h2 {
    font-size: 48px;
  }

  .text-getting-married {
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .text-h3 {
    font-size: 24px;
  }

  .text-h4 {
    font-size: 20px;
    max-width: 480px;
  }

  .text-date {
    font-size: 50px;
    margin: 20px 0;
  }
}

.our-name {
  z-index: 2;
}

.font-dancing {
  font-family: "Dancing Script", cursive;
}

.font-crimson {
  font-family: "Crimson Text", serif;
}

.font-amatic {
  font-family: "Amatic SC", cursive;
}

.font-hanyi {
  font-family: "hanyi";
}
